<template>
	<w-text-input v-model="copiedValue" clearable inputmode="search" :label="label" prepend-icon="search" type="search" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import Validator from '@/mixins/Validator'

/**
 * @displayName w-search-input
 *
 * @since 0.3.2
 */
export default {
	name: 'WSearchInput',
	mixins: [Validator],
	props: {
		label: {
			required: false,
			type: String,
			default: ''
		},
		value: {
			required: false,
			type: String,
			default: ''
		}
	},
	computed: {
		copiedValue: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
